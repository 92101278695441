import React, { useCallback, useMemo } from 'react';

import { globalState } from '../../../../shared/foreground/models';
import { toggleHideLeftPanelOnEnteringReadingView, toggleHidePanelsOnEnteringReadingView, toggleHideRightPanelOnEnteringReadingView } from '../../../../shared/foreground/stateUpdaters/clientStateUpdaters/other';
import { DropdownOptionType } from './Dropdown';
import SelectDropdown from './SelectDropdown';

export default function SidePanelsVisibilityDropdown({ triggerClassName = '' }: { triggerClassName?: string; }) {
  const hideLeftPanelOnEnteringReadingView = globalState(useCallback((state) => state.client.hideLeftPanelOnEnteringReadingView, []));
  const hideRightPanelOnEnteringReadingView = globalState(useCallback((state) => state.client.hideRightPanelOnEnteringReadingView, []));
  const hidePanelsOnEnteringReadingView = useMemo(() => hideLeftPanelOnEnteringReadingView && hideRightPanelOnEnteringReadingView, [hideLeftPanelOnEnteringReadingView, hideRightPanelOnEnteringReadingView]);

  const selectedOption = useMemo(() => {
    if (hidePanelsOnEnteringReadingView) {
      return 'Both panels hidden';
    }

    if (hideLeftPanelOnEnteringReadingView) {
      return 'Left panel hidden';
    }

    if (hideRightPanelOnEnteringReadingView) {
      return 'Right panel hidden';
    }

    return 'Both panels visible';
  }, [hideLeftPanelOnEnteringReadingView, hideRightPanelOnEnteringReadingView, hidePanelsOnEnteringReadingView]);

  const options = useMemo(() => [{
    type: DropdownOptionType.Item,
    name: 'Both panels hidden',
    checked: hidePanelsOnEnteringReadingView,
    onSelect: () => {
      toggleHidePanelsOnEnteringReadingView({ userInteraction: 'click' });
    },
  }, {
    type: DropdownOptionType.Item,
    name: 'Left panel hidden',
    checked: hideLeftPanelOnEnteringReadingView && !hidePanelsOnEnteringReadingView,
    onSelect: () => {
      toggleHideLeftPanelOnEnteringReadingView({ userInteraction: 'click' });
    },
  }, {
    type: DropdownOptionType.Item,
    name: 'Right panel hidden',
    checked: hideRightPanelOnEnteringReadingView && !hidePanelsOnEnteringReadingView,
    onSelect: () => {
      toggleHideRightPanelOnEnteringReadingView({ userInteraction: 'click' });
    },
  },
  ], [hidePanelsOnEnteringReadingView, hideLeftPanelOnEnteringReadingView, hideRightPanelOnEnteringReadingView]);

  return (
    <SelectDropdown
      options={options}
      triggerClassName={triggerClassName}
      selectedOption={selectedOption}
    />
  );
}
