import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { showModal } from '../../../shared/foreground/actions/modals';
import clearAllLocalData from '../../../shared/foreground/clearAllLocalData';
import { globalState } from '../../../shared/foreground/models';
import background from '../../../shared/foreground/portalGates/toBackground/singleProcess';
import { setTheme, toggleAutoAdvance } from '../../../shared/foreground/stateUpdaters/clientStateUpdaters/other';
import useDocumentLocations from '../../../shared/foreground/utils/useDocumentLocations';
import { DisplayTheme } from '../../../shared/types';
import { isDevOrTest } from '../../../shared/utils/environment';
import getDocumentLocationDisplayName from '../../../shared/utils/getDocumentLocationDisplayName';
import Button from './Button';
import DefaultViewDropdown from './Dropdown/DefaultViewDropdown';
import HelpDropdown from './Dropdown/HelpDropdown';
import SidePanelsVisibilityDropdown from './Dropdown/SidePanelsVisibilityDropdown';
import DarkThemeIcon from './icons/DarkThemeIcon';
import LightThemeIcon from './icons/LightThemeIcon';
import SystemThemeIcon from './icons/SystemThemeIcon';
import styles from './PreferencesPage.module.css';
import StandardPage from './StandardPage';
import standardPageStyles from './StandardPage.module.css';
import Switch from './Switch';

export const PreferencesPage = () => {
  const currentTheme = globalState(useCallback((state) => state.client.theme, []));
  const isDarkMode = currentTheme === DisplayTheme.Dark;

  return (
    <StandardPage>
      <div className={`${standardPageStyles.standardPageWrapper} ${styles.preferencesWrapper} ${isDarkMode ? styles.isDarkMode : ''}`}>
        <div className={standardPageStyles.header}>
          <h1 className={standardPageStyles.title}>Account settings</h1>
        </div>

        <div className={`${standardPageStyles.contentMainWrapper} ${styles.contentMainWrapper} has-visible-scrollbar`}>
          <div className={standardPageStyles.contentWrapper}>
            <aside>
              <Link to="/profile">
                Profile
              </Link>
              <Link to="/preferences" className={standardPageStyles.isActive}>
                Preferences
              </Link>
              <Link to="/integrations">
                Integrations
              </Link>
              <Link to="/product-emails">
                Product Emails
              </Link>
              <br />
              <Link to="/import">
                Import Documents
              </Link>
            </aside>

            <div className={styles.content}>
              <h1 className={styles.mainHeader}>Preferences</h1>

              <div className={styles.list}>
                <ListItem
                  title="Interface theme"
                  subtitle="Customize your interface color theme."
                >
                  <div className={styles.themePicker}>
                    <ThemePicker
                      isActive={currentTheme === DisplayTheme.Light || currentTheme === DisplayTheme.Default}
                      theme={DisplayTheme.Light}
                      icon={<LightThemeIcon />}
                      className={styles.lightTheme}
                    />
                    <ThemePicker
                      isActive={currentTheme === DisplayTheme.Dark}
                      theme={DisplayTheme.Dark}
                      icon={<DarkThemeIcon className={styles.darkThemeIcon} />}
                      className={styles.darkTheme}
                    />
                    <ThemePicker
                      isActive={currentTheme === DisplayTheme.System}
                      theme={DisplayTheme.System}
                      icon={<SystemThemeIcon />}
                      className={styles.systemTheme}
                    />
                  </div>
                </ListItem>

                <DefaultView />

                <AutoAdvance />

                <LibraryConfiguration />

                <PanelsVisibility />

                <KeyboardShortcuts />

                {isDevOrTest && <Section id="developer" title="Developer">
                  <div className={styles.developerActionsWrapper}>
                    <Button variant="secondary" onClick={clearAllLocalData}>Clear all local data</Button>
                    <Button variant="secondary" to="/database-query-optimizer">Database Query Optimizer</Button>
                    <Button variant="secondary" onClick={background.downloadSyncerCacheForDebugging}>Download debugging data</Button>
                    <Button variant="secondary" to="/database-explorer">Database Explorer</Button>
                  </div>
                </Section>}
              </div>

              <div className={styles.helpDropdownWrapper}>
                <HelpDropdown />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StandardPage>
  );
};

const Section = ({ id, title, titleClassName = '', children }: { id: string; title: string; titleClassName?: string; children: React.ReactNode; }) => {
  return (
    <section id={id} className={styles.section}>
      <div className={styles.titleWrapper}>
        <h2 className={titleClassName}>{title}</h2>
      </div>
      {children}
    </section>
  );
};

const ListItem = ({ title, subtitle, isSmall, children }: { title: string; subtitle: string | React.ReactNode; isSmall?: boolean; children: React.ReactNode; }) => {
  return <div className={`${styles.listItem} ${isSmall ? styles.isSmall : ''}`}>
    <div className={styles.left}>
      <div className={styles.textWrapper}>
        <div className={styles.title}>
          <h1>{title}</h1>
        </div>
        <div className={styles.subtitle}>
          {subtitle}
        </div>
      </div>
    </div>

    <div className={styles.right}>
      {children}
    </div>
  </div>;
};

const ThemePicker = ({ icon, isActive, theme, className = '' }: { icon: JSX.Element; isActive: boolean; theme: DisplayTheme; className?: string; }) => {
  return (
    <Button className={`${className} ${styles.theme} ${isActive ? styles.themeIsActive : ''}`} variant="unstyled" onClick={() => setTheme(theme, { userInteraction: 'click' })}>
      {icon}
    </Button>
  );
};

const DefaultView = () => {
  return (
    <ListItem
      title="Default view"
      subtitle="Default view that opens when you launch Reader."
    >
      <div className={styles.defaultView}>
        <DefaultViewDropdown />
      </div>
    </ListItem>
  );
};

const AutoAdvance = () => {
  const autoAdvance = globalState(useCallback((state) => state.client.autoAdvance, []));

  return (
    <ListItem
      title="Auto-advance"
      subtitle="Proceed to the next document after taking an action, instead of returning to the list."
    >
      <div className={styles.autoAdvance}>
      <Switch
        rootProps={{
          checked: autoAdvance,
          onCheckedChange: () => toggleAutoAdvance({ userInteraction: 'click', showToast: true }),
          id: 'extension-auto-highlighting-checkbox',
        }} />
      </div>
    </ListItem>
  );
};

const PanelsVisibility = () => {
  return (
    <ListItem
      title="Side panel visibility by default"
      subtitle="Determines whether the side panels are hidden or visible when you first enter reading view."
    >
      <div className={styles.sidePanelVisibility}>
        <SidePanelsVisibilityDropdown />
      </div>
    </ListItem>
  );
};

const KeyboardShortcuts = () => {
  return (
    <ListItem
      title="Keyboard shortcuts"
      subtitle="Personalize keyboard shortcuts to your own liking."
    >
      <div className={styles.keyboardShortcuts}>
        <Button variant="unstyled" to="/preferences/shortcuts">
          Customize
        </Button>
      </div>
    </ListItem>
  );
};

const LibraryConfiguration = () => {
  const documentLocations = useDocumentLocations();
  const currentSetting = useMemo(() => getDocumentLocationDisplayName(documentLocations), [documentLocations]);

  return (
    <ListItem
      title="Library configuration"
      subtitle="Change your desired workflow"
    >
      <div className={styles.defaultView}>
        <Button variant="unstyled" onClick={() => showModal({ id: 'library-locations' }, { userInteraction: 'unknown' })}>
          {currentSetting}
        </Button>
      </div>
    </ListItem>
  );
};
