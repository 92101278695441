import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useState } from 'react';

import SelectIcon from '../icons/20StrokeSelect';
import { Dropdown, DropdownOption } from './Dropdown';
import styles from './SelectDropdown.module.css';

export default function SelectDropdown({ selectedOption, options, triggerClassName = '' }: { selectedOption: string; options: DropdownOption[]; triggerClassName?: string; }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown
      appendToDocumentBody
      isOpen={isOpen}
      options={options}
      setIsOpen={setIsOpen}
      contentAlignment="end"
      contentClassName={styles.dropdownContent}
      trigger={
        <DropdownMenu.Trigger asChild>
          <div className={`${triggerClassName} ${styles.dropdownTrigger}`}>
            {selectedOption}
            <SelectIcon />
          </div>
        </DropdownMenu.Trigger>
      }
    />
  );
}
